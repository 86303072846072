<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keywords"
        :placeholder="t('table.keywords')"
        style="width: 210px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        {{ t("table.search") }}
      </el-button>
    </div>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      fit
      highlight-current-row
      empty-text="暂无数据"
      style="width:100%"
    >
      <el-table-column
        :label="t('table.id')"
        prop="id"
        align="center"
        width="80px"
      >
        <template #default="{row}">
          <span>{{ (listQuery.page - 1) * listQuery.pageSize + list.indexOf(row) + 1 }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="t('table.shipNameAndvoyageNumber')"
        width="300px"
      >
        <template #default="{row}">
          <span>{{ row.vesselName }}/{{ row.voyageNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.checkedByTotal')"
        align="center"
      >
        <template #default="{row}">
          <span>{{ row.completedQuantity }}/{{ row.quantity }}</span>
        </template>
      </el-table-column>
      <el-table-column width="120px">
        <template #default="{row}">
          <el-button
            v-show="row.completedQuantity > 0"
            v-waves
            class="filter-item"
            type="primary"
            icon="el-icon-download"
            @click="handleUpdate(row)"
          >
            {{ t("table.download") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      v-show="total > 0"
      v-model:page="listQuery.page"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
    />
  </div>
  <a ref="adownloading" />

  <el-dialog
    title="下载"
    v-model="dialogFormVisible"
  >
    <el-table
      ref="multipleTableNode"
      v-loading="listLoading"
      :data="availableBrands"
      element-loading-text="Loading..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        align="center"
      />
      <el-table-column label="名称">
        <template #default="{row}">
          {{ row.cnName }}
        </template>
      </el-table-column>
    </el-table>
    <div
      class="dialog-footer"
      style="padding-top: 10px;"
    >
      <el-button
        type="primary"
        :disabled="multipleSelection.length === 0"
        :loading="downloadLoading !== -1"
        @click="handleDownload"
      >
        {{ t("table.confirm") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import {
  download,
  getItems
} from '@/apis/project'
import {
  getBrands
} from '@/apis/brand'
import { VehicleModel } from '@/model/vehicleModel'
import { useI18n } from 'vue-i18n'
import networkConfig from '@/config/default/net.config'
import { ElLink } from 'element-plus'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const adownloading = ref(ElLink)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<VehicleModel>(),
      brands: Array<any>(),
      availableBrands: Array<any>(),
      total: 0,
      listLoading: true,
      excelURL: '',
      listQuery: {
        page: 1,
        pageSize: 10,
        keywords: ''
      },
      downLoadQuery: {
        projectId: undefined,
        brandIds: Array<number>()
      },
      dialogFormVisible: false,
      multipleSelection: [],
      pageviewsData: [],
      downloadLoading: -1,
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.page = index
        }
        if (size) {
          dataMap.listQuery.pageSize = size
        }
        dataMap.listLoading = true
        const data = await getItems(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      async getBrandList() {
        dataMap.listLoading = true
        const data = await getBrands(dataMap.listQuery)
        dataMap.brands = data?.data.items ?? []
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        dataMap.listQuery.page = 1
        dataMap.getList()
      },
      handleUpdate(row: any) {
        dataMap.downLoadQuery.projectId = row.id
        dataMap.availableBrands = dataMap.brands.filter((item: any) => row.brandIds.includes(item.id))
        dataMap.dialogFormVisible = true
      },
      async handleDownload() {
        dataMap.downloadLoading = 1
        dataMap.downLoadQuery.brandIds = dataMap.multipleSelection.map((u: any) => u.id as number)
        const data = await download(dataMap.downLoadQuery)
        dataMap.excelURL = networkConfig.host?.replace('/api', '') + data?.data
        adownloading.value.href = dataMap.excelURL
        setTimeout(() => {
          dataMap.downloadLoading = -1
          adownloading.value.click()
        }, 3 * 1000)
      }
    })
    const handleSelectionChange = (value: any) => {
      dataMap.multipleSelection = value
    }
    onMounted(() => {
      dataMap.getBrandList()
      dataMap.getList(null, 10)
    })
    return { t, adownloading, ...toRefs(dataMap), handleSelectionChange }
  }
})
</script>
