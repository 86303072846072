
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import {
  download,
  getItems
} from '@/apis/project'
import {
  getBrands
} from '@/apis/brand'
import { VehicleModel } from '@/model/vehicleModel'
import { useI18n } from 'vue-i18n'
import networkConfig from '@/config/default/net.config'
import { ElLink } from 'element-plus'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const adownloading = ref(ElLink)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<VehicleModel>(),
      brands: Array<any>(),
      availableBrands: Array<any>(),
      total: 0,
      listLoading: true,
      excelURL: '',
      listQuery: {
        page: 1,
        pageSize: 10,
        keywords: ''
      },
      downLoadQuery: {
        projectId: undefined,
        brandIds: Array<number>()
      },
      dialogFormVisible: false,
      multipleSelection: [],
      pageviewsData: [],
      downloadLoading: -1,
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.page = index
        }
        if (size) {
          dataMap.listQuery.pageSize = size
        }
        dataMap.listLoading = true
        const data = await getItems(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      async getBrandList() {
        dataMap.listLoading = true
        const data = await getBrands(dataMap.listQuery)
        dataMap.brands = data?.data.items ?? []
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        dataMap.listQuery.page = 1
        dataMap.getList()
      },
      handleUpdate(row: any) {
        dataMap.downLoadQuery.projectId = row.id
        dataMap.availableBrands = dataMap.brands.filter((item: any) => row.brandIds.includes(item.id))
        dataMap.dialogFormVisible = true
      },
      async handleDownload() {
        dataMap.downloadLoading = 1
        dataMap.downLoadQuery.brandIds = dataMap.multipleSelection.map((u: any) => u.id as number)
        const data = await download(dataMap.downLoadQuery)
        dataMap.excelURL = networkConfig.host?.replace('/api', '') + data?.data
        adownloading.value.href = dataMap.excelURL
        setTimeout(() => {
          dataMap.downloadLoading = -1
          adownloading.value.click()
        }, 3 * 1000)
      }
    })
    const handleSelectionChange = (value: any) => {
      dataMap.multipleSelection = value
    }
    onMounted(() => {
      dataMap.getBrandList()
      dataMap.getList(null, 10)
    })
    return { t, adownloading, ...toRefs(dataMap), handleSelectionChange }
  }
})
